import React from "react";

import { Router, useLocation } from '@reach/router';
import qs from 'qs';

import { Container, SEOPage, Breadcrumb } from "../components";

import SearchApp from "cre-plugin/src/components/search";

const urlToSearchState = location => qs.parse(location.search.slice(1));

class Home extends React.Component {

  render() {

    const GetLocation = () => {
      const location = useLocation();
      urlToSearchState(location)
    }

    return (

      <Container>

        <div>

          <SEOPage
            title="Search"
            keywords="search, vacancies"
            description="Search our extensive vacancy portfolio."
            url={`/listings/search`}
          />

          <Breadcrumb show={true} data={{ type: "search", mainCat: "Property finder", image: "https://res.cloudinary.com/barrow/image/upload/f_auto/Barrow_page_headers-properties_desktop_wbqonu.jpg" }} />

          <Router basepath="/listings">

            <SearchApp
              path="/search"
              type="main"
              state={{
                searchState: GetLocation,
                refresh: false
              }} />

          </Router>

        </div>

      </Container>
  )
}
}

export default Home